const DEFAULT_IMAGE_QUALITY = process.env.REACT_APP_CONTENTFUL_IMAGE_QUALITY || 75;
const DEFAULT_IMAGE_FORMAT = process.env.REACT_APP_CONTENTFUL_IMAGE_FORMAT || 'webp';
const SUPPORTED_FORMATS = ['jpg', 'png', 'webp', 'gif', 'avif', 'tiff'];
const SUPPORTED_FITS = ['pad', 'fill', 'scale', 'crop', 'thumb'];
const SUPPORTED_FOCUSES = [
  'center',
  'top',
  'right',
  'left',
  'bottom',
  'top_right',
  'top_left',
  'bottom_right',
  'bottom_left',
  'face',
  'faces',
];

// Transform a contentful image url by appending query parameters
// that work with the contentful image api
export const buildContentfulImageURL = (inputImageURL, options = {}) => {
  if (!inputImageURL) {
    return inputImageURL;
  }

  const { width, height, quality, format, fit, focus, radius, bgColor } = options;

  let url;
  let params;
  try {
    url = new URL(inputImageURL);
    params = new URLSearchParams(url.search);
  } catch (e) {
    return inputImageURL;
  }

  params.set('q', quality || DEFAULT_IMAGE_QUALITY);
  params.set('fm', format && SUPPORTED_FORMATS.includes(format) ? format : DEFAULT_IMAGE_FORMAT);

  if (width) {
    params.set('w', width);
  }
  if (height) {
    params.set('h', height);
  }
  if (radius) {
    params.set('r', radius);
  }
  if (bgColor) {
    params.set('bg', bgColor);
  }
  if (fit && SUPPORTED_FITS.includes(fit)) {
    params.set('fit', fit);
  }
  if (focus && SUPPORTED_FOCUSES.includes(focus)) {
    params.set('f', focus);
  }

  url.search = params.toString();
  return url.toString();
};

export default { buildContentfulImageURL };
