/* eslint-disable react/forbid-prop-types */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { trackClickEvent } from '@utils/analytics';
import AnchorLink from '../AnchorLink';
import { HOME_URL } from '../../constants/routes';
import s from './Footer.module.scss';
import { fetchNavigation } from '../../utils/contentfulHelpers';
import Logo from '../Logo';
import SocialShareLinks from '../SocialShareLinks';

const getKey = (name) => name.toLowerCase().replace(/\s+/, '-');

const tradeMark = `© Wizarding World Digital ${new Date().getFullYear()}`;

const propTypes = {
  footerData: PropTypes.shape({}).isRequired,
};

const Footer = ({ footerData }) => {
  const [items, setItems] = useState(footerData.items);

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetchNavigation('footer');
      // eslint-disable-next-line no-underscore-dangle
      if (data && Date.parse(data._updatedAt) > Date.parse(footerData._updatedAt)) {
        setItems(data.items);
      }
    };
    fetchData();
  }, [footerData, setItems]);

  const renderFooterLinks = (item, index) => {
    if (item.displayText === 'Privacy Settings') {
      // link text is controlled via OneTrust settings and thus should be left blank
      return (
        <li className={s.linkItem} key="ot-sdk-btn">
          <a
            id="ot-sdk-btn"
            aria-label={item.displayText}
            onClick={(e) => {
              trackClickEvent({
                destination_url: item.url,
                label: e.target.text,
                location: 'Footer',
                vertical_index: -1,
                horizontal_index: index,
              });
            }}
            data-testid="privacy-settings-link"
            className={[s.fauxLink, 'ot-sdk-show-settings'].join(' ')}
          >
            {''}
          </a>
        </li>
      );
    }

    return (
      <li className={s.linkItem} key={getKey(item.displayText)}>
        <AnchorLink
          href={item.url}
          aria-label={item.displayText}
          title={item.displayText}
          target={item.displayText === 'FAQs' ? '_blank' : undefined}
          rel={item.displayText === 'FAQs' ? 'noopener noreferrer' : undefined}
          onClick={() => {
            trackClickEvent({
              destination_url: item.url,
              label: item.displayText,
              location: 'Footer',
              vertical_index: -1,
              horizontal_index: index,
            });
          }}
        >
          {item.displayText}
        </AnchorLink>
      </li>
    );
  };

  return (
    <div className={s.root}>
      <div className={s.innerBorder}>
        <div className={s.outerContainer}>
          <div className={s.upperContainer}>
            <ul className={s.links}>
              {items.map((item, index) => renderFooterLinks(item, index))}
            </ul>
            <div className={s.logo}>
              <AnchorLink
                href={HOME_URL}
                onClick={() => {
                  trackClickEvent({
                    destination_url: HOME_URL,
                    label: 'Wizarding World Logo',
                    location: 'Footer',
                    vertical_index: -1,
                    horizontal_index: 0,
                  });
                }}
                data-testid="footer-logo"
              >
                <Logo width="100%" placement="footer" />
              </AnchorLink>
            </div>
          </div>
          <hr className={s.divider} />
          <div className={s.lowerContainer}>
            <div className={s.footerText}>
              <p className={s.trademark}>{tradeMark}</p>
              <p>
                Looking for Wizarding World?&nbsp;
                <AnchorLink className={s.footerLink} href={HOME_URL}>
                  HarryPotter.com
                </AnchorLink>
                &nbsp;is the official home of Harry Potter, Fantastic Beasts, and the Wizarding
                World, formerly known as Pottermore.
              </p>
              <p>
                Brought to you by Wizarding World Digital and its parent company Warner Bros.
                Entertainment Inc., delivering the latest news and official products from the
                Wizarding World and our partners.
              </p>
              <p>
                All characters and elements © & TM Warner Bros. Entertainment Inc. Publishing Rights
                © J.K. Rowling.
              </p>
            </div>
            <div className={s.socialShare}>
              <SocialShareLinks
                socialMediaItems={footerData?.socialMediaItems}
                mobile={false}
                analyticsParams={{
                  location: 'Footer',
                  vertical_index: -1,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Footer.propTypes = propTypes;
export default Footer;
